document.addEventListener('DOMContentLoaded', function(){
    const body=document.querySelector('body');
    function start(){
      body.classList.add('start');

    }
  
        let e=document.querySelector(".image_z");
        let activeRotate=true;
        function clicks(ev){
            if(activeRotate){
                activeRotate=false;
                return false;
            }
            activeRotate=true;
            let i=(ev)?"right":"left";
            body.classList.remove("rotate_right","rotate_left");
            setTimeout(() => body.classList.add("rotate_"+i), 10);
            setTimeout(() => activeRotate=false, 500);
        }
  
  
        const menu=document.getElementById("menu_icon"),
              main=document.querySelector(".main"),
              root=document.getElementById("root"),
              rootMenu=document.querySelector(".root_menu"),
              sliderArrowNext=document.querySelector(".slider-arrow-next");
              //menuArrow=["Home", "Possibility", "Demo app", "Shop", "News"];

              let menuArrow=sliderArrowNext.querySelectorAll("div");

              function changeMenuArrow(i){
                menuArrow.forEach((e, i) => {
                    e.classList.remove("active");
                });
                menuArrow[i].classList.add("active");
              }      




            //   sliderArrowNext.innerHTML=menuArrow[2];
            function menuClick() {
                const height=main.offsetHeight
                const e_height=e.offsetHeight
                if(menu.classList.contains('menu_active')){
                    menu.querySelectorAll('.button-play').forEach((e, i) => {
                        e.beginElement();
                    });
                    main.style.height = null;
                    e.style.height = null;
                    e.style.width = null;
                    menu.classList.remove('menu_active');
                }else{
                    menu.querySelectorAll('.button-pause').forEach((e, i) => {
                        e.beginElement();
                    });
                    main.style.height = height+"px";
                    e.style.height = e_height+"px";
                    e.style.width = e_height+"px";
                    menu.classList.add('menu_active');

                }
                return false;
            };

            

        function menuBar(){
            menuClick();
            main.classList.toggle("menu_active");
            root.classList.toggle("menu_active");
            rootMenu.classList.toggle("menu_active");
        }
        menu.addEventListener("click", menuBar);
        main.addEventListener("click", function(e){ if(this.classList.contains("menu_active")){menuBar();}})
        document.getElementById("logo").addEventListener("click", (e)=>{paginatorChange(e,0);if(main.classList.contains("menu_active")){menuBar();}})
  
        let paginator = document.querySelector(".paginator"),
        paginators = paginator.querySelectorAll("div"),
        paginatorBg = paginator.querySelector("i"),
        activeTab=0,
        tabs=paginators.length-1;
        
        let paginatorWith=(paginator.offsetWidth/paginators.length);
        paginatorBg.style.width=paginatorWith+'px';
        function paginatorChange(e,el){
            console.log("activeTab: "+activeTab+" el: "+el);
            clicks(activeTab<=el);
            if(el<0)el=tabs;
            if(el>tabs)el=0;
            let menuArrowN=1+el;
            if(menuArrowN<0)menuArrowN=tabs;
            if(menuArrowN>tabs)menuArrowN=0;
            body.classList.remove('tab_'+activeTab);
            setTimeout(() => body.classList.add("tab_"+el), 30);
            //sliderArrowNext.innerHTML=menuArrow[menuArrowN];
            changeMenuArrow(menuArrowN);
            paginators[activeTab].classList.remove("active");
            activeTab=el;
            paginators[el].classList.add("active");
            paginatorBg.style.width=(paginatorWith*(1+el))+'px';
        }
        paginators.forEach((e, i) => {
            e.addEventListener("click", (e)=>{if(i!==activeTab)paginatorChange(e,i)})
        });
        var page_navigations = document.querySelectorAll(".page_navigations .href_");
        page_navigations.forEach((e, i) => {
            e.addEventListener("click", (e)=>{if(i!==activeTab){menuBar();paginatorChange(e,i);}})
        });

        sliderArrowNext.addEventListener("click", (e)=>{paginatorChange(e,1+activeTab)});
        document.onkeydown = checkKey;
        function checkKey(e) {
            e = e || window.event;
            switch(e.keyCode) {
                case 38: 
                    // up arrow
                    break
            
                case 40:
                    // down arrow
                    break
        
                case 37:
                    paginatorChange(e,activeTab-1);
                    break
        
                case 39:
                    paginatorChange(e,activeTab+1);
                    break
                    
                default:
                    //another
                    break
              }
  
            // if (e.keyCode =='38') {
            //     // up arrow
            // }
            // else if (e.keyCode == '40') {
            //     // down arrow
            // }
            // else if (e.keyCode =='37') {
            // // left arrow
            //     paginatorChange(e,activeTab-1);
            // }
            // else if (e.keyCode =='39') {
            // // right arrow
            //     paginatorChange(e,activeTab+1);
            // }
        }


	var xDown = null;
	var yDown = null;

	function handleTouchStart(evt) {
		xDown = evt.touches[0].clientX;
		yDown = evt.touches[0].clientY;
	}

	function handleTouchMove(evt) {
		if (!xDown || !yDown) {
			return;
		}

		var xUp = evt.touches[0].clientX;
		var yUp = evt.touches[0].clientY;

		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;
        //console.log("xDiff: "+xDiff+"   Math.abs(xDiff): "+Math.abs(xDiff)+" > Math.abs(yDiff): "+Math.abs(yDiff));
		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			if (xDiff > 0) {
				//swipe(1);
                paginatorChange(e,activeTab+1);
			} else {
				//swipe(-1);
                paginatorChange(e,activeTab-1);
			}
		}
		/* reset values */
		xDown = null;
		yDown = null;
	}

	main.addEventListener('touchstart', handleTouchStart, false);
	main.addEventListener('touchmove', handleTouchMove, false);
    paginatorChange(e,activeTab);











    const vh = window.innerHeight / 100;
    const max_swipe=(+vh*90);
    const min_swipe=(+vh*30);

    const scrollingDiv=document.querySelector(".possibility_popap_content");

    function makeResizableDiv(div) {
        const element = document.querySelector(div);
        //const resizers = document.querySelectorAll(div + ' .resizer');

        let original_height = 0;
        //let original_y = 0;
        let original_mouse_y = 0;

        //for (let i = 0;i < resizers.length; i++) {
        const currentResizer = element;//resizers[i];
        currentResizer.addEventListener('mousedown', function(e) {
            e.preventDefault()
            original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
            //original_y = element.getBoundingClientRect().top;
            original_mouse_y = e.pageY;
            window.addEventListener('mousemove', resize)
            window.addEventListener('mouseup', stopResize)
        })
        
        function resize(e) {
            element.style.transition='none';

                let height = original_height - (e.pageY - original_mouse_y)
                if (height<max_swipe&&height>min_swipe) {
                    element.style.height = height + 'px';
                }
        }
        
        function stopResize() {
            window.removeEventListener('mousemove', resize);
            element.style.transition='';
        }
    }
    



    var touchstart_y,touchmove_y;
const resizable_element = document.querySelector('.resizable');

resizable_element.addEventListener("touchstart",
        function clicked(e) {
            var br = resizable_element.getBoundingClientRect();
            var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
            var touch = evt.touches[0] || evt.changedTouches[0];
            touchstart_y = touch.clientY - br.top;
        }
    );    
    resizable_element.addEventListener("touchmove",
        function clicked(e) {
            var br = resizable_element.getBoundingClientRect();
            var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
            var touch = evt.touches[0] || evt.changedTouches[0];
            touchmove_y = touch.clientY - br.top;
            let original_height = parseFloat(getComputedStyle(resizable_element, null).getPropertyValue('height').replace('px', ''));
            let height = original_height - (touchmove_y - touchstart_y);
            if(touchmove_y<touchstart_y){//swipe top
                if(height<max_swipe){
                    e.preventDefault();
                    ////resizable_element.style.transition='none';
                    resizable_element.style.height = height + 'px';
                }
            }else{//swipe bottom
                if(scrollingDiv.scrollTop===0)e.preventDefault();
                if(height>min_swipe&&scrollingDiv.scrollTop===0){
                    ////resizable_element.style.transition='none';
                    resizable_element.style.height = height + 'px';
                }else{
                    touchstart_y=touchmove_y;
                }
            }
        }
    );

    var resizer_top=document.getElementById("swipe-wrap");
    resizer_top.addEventListener("touchstart", ()=>{resizer_top.classList.add("active");});
    resizer_top.addEventListener("touchmove",
    function clicked(e) {
        e.preventDefault();
        var br = resizable_element.getBoundingClientRect();
        var evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
        var touch = evt.touches[0] || evt.changedTouches[0];
        touchmove_y = touch.clientY - br.top;
        let original_height = parseFloat(getComputedStyle(resizable_element, null).getPropertyValue('height').replace('px', ''));
        let height = original_height - (touchmove_y - touchstart_y);
        if(height<max_swipe&&height>min_swipe){
            //e.preventDefault();
        }else{
            return false;
        }
        resizable_element.style.height = height + 'px';
    }
);
    resizer_top.addEventListener("touchend", ()=>{
        resizer_top.classList.remove("active");
    });

    makeResizableDiv('.resizable')


    // var scrollingDivscrollHeight=scrollingDiv.documentElement.scrollHeight;
    // var scrollingDivclientHeight=scrollingDiv.documentElement.clientHeight;
    // var scrollingDivheight=scrollingDivscrollHeight+scrollingDivclientHeight;
    // var scrollingDivscrollTop=scrollingDiv.pageYOffset || scrollingDiv.documentElement.scrollTop||scrollingDiv.body.scrollTop;


    // function getDocHeight() {
    //     var D = document;
    //     return Math.max(
    //         D.body.scrollHeight, D.documentElement.scrollHeight,
    //         D.body.offsetHeight, D.documentElement.offsetHeight,
    //         D.body.clientHeight, D.documentElement.clientHeight
    //     );
    // }
    scrollingDiv.addEventListener('scroll', () => { 
        if (scrollingDiv.scrollTop + scrollingDiv.clientHeight > (scrollingDiv.scrollHeight||scrollingDiv.pageYOffset)-30) {
            resizable_element.style.zIndex = "190";
        }else{
            resizable_element.style.zIndex = "230";
        }
    });







    const howItWork=document.querySelector(".how_it_work_content");
    const demoPopap=document.querySelector(".demo_popap");
    const demoPopapLeft=demoPopap.querySelector(".left_arrow");
    const demoPopapRight=demoPopap.querySelector(".right_arrow");
    const howItWorkSlides=howItWork.querySelectorAll(".how_it_work_content_phone_figure");
    const howItWorkSlidesCount=howItWorkSlides.length-1;
    let howItWorkSlide=0;

    function demoSlides(i){
        console.log("howItWork: "+i);
        if(i<0 || i>howItWorkSlidesCount)return false;
        demoPopapLeft.classList.add("active");
        demoPopapRight.classList.add("active");
        howItWork.classList.remove("how_"+howItWorkSlide);
        if(i<=0)demoPopapLeft.classList.remove("active");
        if(i>=howItWorkSlidesCount)demoPopapRight.classList.remove("active");
        howItWorkSlides[howItWorkSlide].classList.remove("active");
        howItWorkSlides[i].classList.add("active");
        howItWorkSlide=i;
        howItWork.classList.add("how_"+i);

    }

    demoPopapLeft.addEventListener("click", ()=>{demoSlides(howItWorkSlide-1);})
    demoPopapRight.addEventListener("click", ()=>{demoSlides(howItWorkSlide+1);})




    ///news   
    const newsPopap = document.querySelector(".news_popap");
    const newsBlock = document.querySelectorAll(".news_block");
    const newsBlockFigure = document.querySelectorAll(".news_block_figure");
    const newsBlockArticle = document.querySelectorAll(".news_block_article");
    function showNewsBlock(i){
        newsBlock[i].classList.toggle("active");
        var panel = newsBlock[i];//this.nextElementSibling;
        console.log(panel.offsetTop);
        console.log(newsPopap.scrollTop);
        console.log(panel.offsetTop-newsPopap.scrollTop);
        var figure = newsBlockFigure[i];
        var article = newsBlockArticle[i];
        if (!panel.closest(".active")) {
            panel.style.transform = "translate3d(0,0,0)";
            panel.style.marginBottom="10px"
            panel.style.minHeight = "300px";
            figure.style.minHeight= "150px";
            article.style.minHeight= "5.2em";
            newsPopap.style.overflow="overlay";
        } else {
            panel.style.transform = "translate3d(0,"+(panel.offsetTop-newsPopap.scrollTop)*-1+"px,0)";
            //panel.style.maxHeight= newsPopap.clientHeight+ "px";
            panel.style.marginBottom = (panel.offsetTop-newsPopap.scrollTop-10)*-1+"px";
            figure.style.minHeight= figure.scrollHeight + "px";
            article.style.minHeight= article.scrollHeight + "px";
            panel.style.minHeight = newsPopap.clientHeight+ "px";//(article.scrollHeight + figure.scrollHeight)+"px";
            newsPopap.style.overflow="hidden";
        } 
    }
    newsBlock.forEach((e, i) => {
      e.addEventListener("click", (e) =>{showNewsBlock(i)});
    });
    
    

    const onMouseWheelBlock=document.querySelector(".mouse_wheel_block");
    let onMouseWheelIsDone = true;
    function onMouseWheel(e){
        onMouseWheelIsDone = false;
        e.deltaY > 0 
            ? paginatorChange(e,activeTab+1)
            : paginatorChange(e,activeTab-1)
        setTimeout(()=>{
            onMouseWheelIsDone = true;
        }, 1500);
    }

    function scrollPage(){
        onMouseWheelBlock.addEventListener('mousewheel', (e)=>{if(onMouseWheelIsDone)onMouseWheel(e)});
    }

    const typedWrap = document.querySelector(".typed-wrap");
	let descriptions = typedWrap.querySelectorAll("div");//["videos without ads", "video to your device’s memory"];
    const description = descriptions.length-1;
	let timer = 0;
    let typedWrapDelay=1000;


	function typeWriter(timers, e, txt, s = 4, i = s) {
		if (timer !== timers) return false;
		if (i < txt.length) {
			let a = (i < txt.length - 1) ? '_' : '<span class="typed-cursor typed-cursor--blink">_</span>';
			let txti = txt.substring(0, s) + txt.substring(s, 1 + i) + a;
			e.innerHTML = txti;
			i++;
			setTimeout(() => typeWriter(timers, e, txt, s, i), typedWrapDelay / (txt.length - s));
		}else setTimeout(nextTypeWriter, typedWrapDelay*2);
	}
    function nextTypeWriter(){
        typedWrap.innerHTML = '<span class="typed-cursor typed-cursor--blink">_</span>';
        timer=(description===timer)?0:timer+1;
        setTimeout(()=>{typeWriter(timer, typedWrap, descriptions[timer]?.innerText, 0)}, typedWrapDelay);
    }

    if(window.innerWidth>1024){nextTypeWriter();scrollPage();};



    start();






});

(function init100vh(){
    function setHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setHeight();
    window.addEventListener('resize', setHeight);
})();



