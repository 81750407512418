import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Link, Redirect} from 'react-router-dom';
import Dashboard from './Dashboard';
const Page = ({ history }) => {
    let loc = window.location.pathname;
    history.listen((location, action) => {
        // location is an object like window.location
        console.log(action, location.pathname, location.state)
        // if(location.pathname=="/dashboard")
        loc = location.pathname
    });
    console.log("loc: " + loc)
    if (localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== '') {
        return (<Dashboard hide={loc} />)
    } else {
        return <Redirect to={`/`} />
    }
};
export default withRouter(Page);