// const fetchURL = "https://open.cat.dp.ua";
// const fetchURL = "https://staging.opentube.dbvirtualeducation.com";
const fetchURL = process.env.REACT_APP_API_URL;
export const getHomeFetch = e => {
  return dispatch => {
    return fetch(fetchURL + "/webApi", {
      method: "GET",
      headers: {
        'x-client-lang': 'ru',
        'Accept': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data) {
          dispatch(home(data))
        } else {
          console.log('error')
        }
      })
  }
}

const home = obj => ({
  type: 'HOME',
  payload: obj
})

export const getTranslationsLang = (e) => {
  console.log("getTranslationsLang = (e) = " + e);
  // return dispatch => {
  return fetch("https://dpua.github.io/awax/lang.json", {//https://awaxtech.com/api/getTranslations
    method: "POST",
    headers: {
      'x-client-lang': localStorage.locale,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ "lang": e })
  })
    .then(resp => resp.json())
    .then(data => {
      if (data.message) {
        //Тут прописываем логику
        console.log("getTranslationsLang=data.message")
        console.log(data.message)
      } else {
        //localStorage.setItem("lang", data.access_token)
        console.log("getTranslationsLang=data")
        console.log(data)
        // dispatch(getTranslations(data))
      }
    })
  // }
}

const getTranslations = userObj => ({
  type: 'Get_Translations',
  payload: userObj
})

export const userPostFetch = (user, callback) => {
  
  let formData = new FormData()
  formData.append("name", user.name)
  formData.append("email", user.email)
  formData.append("password", user.password)
  formData.append("password_confirmation", user.password_confirmation)

  return dispatch => {
    return fetch(fetchURL + "/webApi/register", {
      method: "POST",
      headers: {
        'x-client-lang': localStorage.locale,
        Accept: 'application/json',
      },
      body: formData
      // body: JSON.stringify(user)
    })
      .then(resp => resp.json())
      .then(data => {
        if (data) {
          callback(data)
          localStorage.setItem("access_token", data?.access_token)
          localStorage.setItem("email", user.email)
          data.logout_time=((+data?.expires_in*1000)+new Date().getTime())
          dispatch(loginUser(data))
        } else {
          callback('error')
          console.log('error')
        }
      })
  }
}

const loginUser = userObj => ({
  type: 'LOGIN_USER',
  payload: userObj
})

export const userPostForgot = user => {
  return dispatch => {
    return fetch("https://site.spilka.dp.ua/forgot.php", {
      method: "POST",
      headers: {
        'x-client-lang': localStorage.locale,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ user })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
          //Тут прописываем логику
        } else {
          //localStorage.setItem("access_token", data.access_token)
          //dispatch(loginUser(data.user))
        }
      })
  }
}

export const userLoginFetch = (user, callback) => {

  let formData = new FormData()
  formData.append("email", user.email)
  formData.append("password", user.password)

  return dispatch => {
    return fetch(fetchURL + "/webApi/login", {
      method: "POST",
      headers: {
        'x-client-lang': 'ru',
        'Accept': 'application/json',
      },
      body: formData
      // body: JSON.stringify({email: user.email, password: user.password})
      // body: JSON.stringify({user})
    })
      .then(resp => resp.json())
      .then(data => {
        if (data) {
          callback(data)
          localStorage.setItem("access_token", data?.access_token)
          localStorage.setItem("email", user?.email)
          data.logout_time=((+data?.expires_in*1000)+new Date().getTime())
          dispatch(loginUser(data))
        } else {
          callback('error')
          console.log('error')
        }
      })
  }
}

const serverError = userObj => ({
  type: 'ERROR',
  payload: userObj
})

const dashboard = userObj => ({
  type: 'DASHBOARD',
  payload: userObj
})
export const getDashboardFetch = () => {
  return dispatch => {
    const access_token = localStorage.access_token;
    if (access_token) {
      return fetch(fetchURL + "/webApi/dashboard", {
        method: "GET",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          if (data) {
            dispatch(dashboard(data));
          } else {
            console.log("error")
          }
        })
    }
  }
}

export const postDashboardKeyAddFetch = (key) => {

  let formData = new FormData()
  formData.append("key", key)

  return dispatch => {
    const access_token = localStorage.access_token;
    if (access_token) {
      return fetch(fetchURL + "/webApi/dashboard/key/add", {
        method: "POST",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${access_token}`
        },
        // body: 'key="'+key+'"'
        body: formData
        //body: JSON.stringify({ "key": key })
      })
        .then(resp => resp.json())
        .then(data => {
          if (data?.message == "Done") {
            // ok
            dispatch(getDashboardFetch())
          } else {
            // error
            dispatch(serverError((data?.message) ? data.message : "Server error"))
          }
        })
    }
  }
}

export const getDashboardKeyRemoveDeviceFetch = (key) => {
  return dispatch => {
    const access_token = localStorage.access_token;
    if (access_token) {
      return fetch(fetchURL + "/webApi/dashboard/key/removeDevice/" + key, {
        method: "GET",
        headers: {
          'x-client-lang': localStorage.locale,
          Accept: 'application/json',
          'Authorization': `Bearer ${access_token}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.message == "Done") {
            // ok
            dispatch(getDashboardFetch())
          } else {
            // error
            dispatch(serverError((data?.message) ? data.message : "Server error"))
          }
        })
    }
  }
}

export const getLogoutUser = () => {
  return dispatch => {
    const access_token = localStorage.access_token;

    localStorage.removeItem("access_token")
    localStorage.removeItem("email")
    dispatch(logoutUser())

    return fetch(fetchURL + "/webApi/logout", {
      method: "GET",
      headers: {
        'x-client-lang': localStorage.locale,
        Accept: 'application/json',
        'Authorization': `Bearer ${access_token}`
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (data.message) {
          // Будет ошибка если access_token не дествительный
          dispatch(logoutUser(data))
        } else {
          // Удаление access_token из localStorage
          dispatch(logoutUser(data))
        }
      })
  }
}

export const logoutUser = () => ({
  type: 'LOGOUT_USER'
})



export const switchMenu = () => ({
  type: 'SWITCH_MENU'
})

