const access_token = (localStorage.getItem('access_token') !== undefined && localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== '') ? localStorage.getItem('access_token') : '';
const currentUserObj = (localStorage.getItem('currentUser') !== undefined && localStorage.getItem('currentUser') !== null && localStorage.getItem('currentUser') !== '') ? JSON.parse(localStorage.getItem('currentUser')) : { "access_token": access_token };

const initialState = {
  home: {},
  currentUser: currentUserObj,
  dashboard: {},
  serverError: '',
  serverOK: true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'HOME':
      return { ...state, home: action.payload }
    case 'ERROR':
      return { ...state, serverError: action.payload, serverOK: true  }
    case 'LOGIN_USER':
      localStorage.setItem('currentUser', JSON.stringify(action.payload));
      return { ...state, currentUser: action.payload}
    case 'DASHBOARD':
      return { ...state, dashboard: action.payload, serverOK: true  }
    case 'LOGOUT_USER':
      localStorage.setItem('currentUser', JSON.stringify({ "access_token": "" }));
      return { ...state, currentUser: {}, dashboard: {} }
    case 'SWITCH_MENU':
      return { ...state }
    default:
      return state;
  }
}

export default reducer;